import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.loading)?_c('h1',[_vm._v("Loading...")]):_c('div',[_c('div',{staticClass:"font-weight-bold my-4"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("homePage.welcomeMessage")))])]),_c('div',{staticClass:"d-flex align-stretch"},[_c('div',{staticClass:"pr-4 flex-1"},[_c(VTooltip,{attrs:{"disabled":_vm.hasPermissions(['seminar:default']),"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"image-button",class:{ locked: !_vm.hasPermissions(['seminar:default']) },on:{"click":function($event){return _vm.$router.push('/seminars')}}},on),[_c('div',{staticClass:"image-button__title"},[_vm._v(" "+_vm._s(_vm.$t("homePage.seminarBtnText"))+" ")]),_c('img',{attrs:{"src":require("@/assets/seminars.png"),"alt":"Seminars Image"}}),_c('div',{staticClass:"image-button__overlay"})])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("homePage.noSemiPermission"))+" ")])])],1),_c('div',{staticClass:"pl-4 flex-1"},[_c(VTooltip,{attrs:{"disabled":_vm.hasPermissions(['analysis:fast', 'analysis:relaxed']),"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"image-button",class:{
                locked: !_vm.hasPermissions([
                  'analysis:fast',
                  'analysis:relaxed' ]),
              },on:{"click":function($event){return _vm.$router.push('/sessions/list')}}},on),[_c('div',{staticClass:"image-button__title"},[_vm._v(" "+_vm._s(_vm.$t("homePage.sessionTrainingBtnText"))+" ")]),_c('img',{attrs:{"src":require("@/assets/sessions.png"),"alt":"Sessions Image"}}),_c('div',{staticClass:"image-button__overlay"})])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("homePage.noSessPermission"))+" ")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }