





































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import { Seminar } from "@/core/models";
import { hasPermissions } from "@/core/utils/auth";

@Component({
  methods: { hasPermissions },
})
export default class Home extends Vue {
  @Getter("profile/loading") loading!: boolean;
  @Getter("seminars/seminars") seminars!: Seminar[];
}
